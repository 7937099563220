import ClipboardJS from "clipboard";

$().ready(function(){
  var clipboard = new ClipboardJS('.to-clipboard');

  // Event, success
  clipboard.on('success', function(e) {
    var $trigger = $(e.trigger);
    if ($trigger.attr("data-opened") == "true") {
      return;
    }
    else {
      $trigger.attr("data-opened", "true");
    }
    
    var $okMsg = $trigger.find(".msg-ok");
    if (typeof $okMsg.html() == 'undefined') {
      var msg = $trigger.attr("data-clipboard-msg-ok");
      $okMsg = $("<span class='msg-ok'>" + msg + "</span>");
      $okMsg.appendTo($trigger);
    }
    $okMsg.fadeIn();

    setTimeout(function(){
      $trigger.attr("data-opened", "");
      $okMsg.fadeOut();
    }, 2000);
    e.clearSelection();
  });

  // Event, error
  clipboard.on('error', function(e) {
      alert("Not supported by your browser");
  });
});
