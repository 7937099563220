// NOTE:GZ:200828 Aquest component és a main però es fa servir també per phone. Hi ha alguna diferència de comportament, que es detecta amb el bool isPhoneVariant

import flatpickr from "flatpickr";
import { Catalan } from "flatpickr/dist/l10n/cat.js"
import { Spanish } from "flatpickr/dist/l10n/es.js"
import { French } from "flatpickr/dist/l10n/fr.js"
import { Portuguese } from "flatpickr/dist/l10n/pt.js"

var lang = $("html").attr("lang");
if (lang == "ca" || lang == "oc") flatpickr.localize(flatpickr.l10ns.cat);
else if (lang == "es") flatpickr.localize(flatpickr.l10ns.es);
else if (lang == "fr") flatpickr.localize(flatpickr.l10ns.fr);
else if (lang == "pt") flatpickr.localize(flatpickr.l10ns.pt);


// Camps del formulari de reserva
// ------------------------------
flatpickr("input.date-reserves", {
  dateFormat: "d/m/Y",
  disableMobile: "true",
  monthSelectorType: "static",
  locale: {
      firstDayOfWeek: 1,
      // weekdays: {
      //   shorthand: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      //   longhand: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
      // },
  },
  onOpen: function(selectedDates, dateStr, instance) {
    $(".flatpickr-calendar").css("position", "fixed");
  },
  onClose: function(selectedDates, dateStr, instance) {
    $(".flatpickr-calendar").css("position", "absolute");
  },
});


$().ready(function(){
  
  var
    $reserves = $(".c-widget-reserves"),
    $dataInici = $reserves.find("#dtd"),
    $dataFi = $reserves.find("#dth"),
    $inputHabitacions = $reserves.find(".habitacions-input"),
    $btAdd = $reserves.find(".add"),
    $btDone = $reserves.find(".reserves-done"),
    $btCancel = $reserves.find(".reserves-cancel"),
    $blocHabitacions = $reserves.find(".habitacions-content"),
    cntHabitacio = 0,
    isPhoneVariant = $inputHabitacions.hasClass("phone-variant"),
    $template = null
  ;
  
  var // per phone
    $main = $("#main"),
    $btClose = $main.children(".bt-close");
  ;
  
  // HACK: he de controlar el display. Només fade, oculta el que hi ha a sota
  if (!isPhoneVariant) $blocHabitacions.css("display", "none");
  
  // Lliguem les dates
  // $dataFi.datepicker("option", "beforeShow", function(){
  //   if ($dataFi.datepicker('getDate') == null) {
  //     var nextDate = $dataInici.datepicker('getDate');
  //     if (nextDate != null) {
  //       nextDate.setDate(nextDate.getDate() + 1);
  //       $dataFi.datepicker('setDate', nextDate);
  //     }
  //   }
  // });
  
  // Trigger a l'input d'habitacions
  $inputHabitacions.on('focus', function(){
    if (isPhoneVariant) {
      // Fly-in
      $blocHabitacions.addClass("active");
      $btClose.addClass("active");
      $main.addClass("noscroll");
    }
    else {
      $blocHabitacions.css("display", "block");
      $blocHabitacions.fadeIn();
    }
  });

  // Preparem template
  $template = $reserves.find(".habitacio-0").clone();
  
  // Afegim spinners
  createSpinners($blocHabitacions.find(".spinner"));
  
  // Handler: afegir habitació
  $btAdd.find("a").on('click', function(e){
    cntHabitacio++;
    if (cntHabitacio > 4) return; // per si de cas
    
    // Afegim habitació
    var $habitacio = createRoom(cntHabitacio);
    $habitacio.insertBefore($btAdd);
    
    // Limitem a 5 habitacions
    if (cntHabitacio == 4) {
      $btAdd.hide();
    }
    e.preventDefault();
  });

  // Handler: done
  $btDone.on('click', function(e){
    amagaBlockHabitacions();
    $inputHabitacions.val((cntHabitacio + 1) + " " + $inputHabitacions.attr("data-text-hab"));
    e.preventDefault();
  });

  // Handler: cancel
  $btCancel.on('click', function(e){
    clearRooms();
    e.preventDefault();
  });

  // Function: create HTML code for a room
  function createRoom(cnt) {
    var
      $habitacio = $template.clone(),
      $legend = $habitacio.find("legend"),
      $adults = $habitacio.find("#adults_0"),
      $nens = $habitacio.find("#nens_0")
    ;
    createSpinners($habitacio.find(".spinner"));
    $legend.text($legend.attr("data-prefix") + (cnt + 1));
    $adults.attr("id", "adults_" + cnt);
    $nens.attr("id", "nens_" + cnt);
    
    return $habitacio;
  }

  // Function: clears rooms HTML (leaves 1)
  function clearRooms() {
    $inputHabitacions.val("");
    amagaBlockHabitacions();
    setTimeout(function(){ // Clear rooms
      $blocHabitacions.find("fieldset").remove();
      var $habitacio = createRoom(0);
      $habitacio.insertBefore($btAdd);
      cntHabitacio = 0;
      $btAdd.show();
    }, 500); // hem de deixar que s'acabi la transició d'ocultar
  }

  // Function: updates the children ages controls
  function updateChildren($trigger) {
    var
      value = $trigger.val(),
      $children = $trigger.closest("fieldset").find(".children"),
      $childrenContent = $children.find(".children-content"),
      $childrenFields = $childrenContent.find(".camp"),
      currNumFields = $childrenFields.length,
      numHabitacio = $trigger.closest("fieldset").find("legend").text().trim().split(" ").pop()
    ;
    if (value == currNumFields) {
      return;
    }
    else if (value == 0) {
      $children.hide();
      $childrenContent.html("");
    }
    else {
      if (value > currNumFields) {
        
        // <div class="camp">
        //   <%= text_field_tag "nens[]", nil, id: "nens_0", class: "small spinner", readonly: true %>
        // </div>
        
        var $newChild = $("<span class='camp'></span>");
        var $newInput = $("<input name='edats_" + (numHabitacio - 1) + "[]' type='text' class='spinner' data-min='1' data-max='12' readonly />");
        $newInput.val(1);
        $newInput.appendTo($newChild);
        $newChild.appendTo($childrenContent);
        createSpinners($newInput);
      }
      else {
        $childrenFields.last().remove();
      }
      $children.show();
    }
  }
  
  function amagaBlockHabitacions() {
    if (isPhoneVariant) {
      $btClose.trigger('click');
    }
    else {
      $blocHabitacions.fadeOut();
      setTimeout(function(){ $blocHabitacions.css("display", "none"); }, 500);
    }
  }
  
  function createSpinners($spinners) {
    $spinners.each(function(){
      var
        $spinnerInput = $(this),
        min = $spinnerInput.attr("data-min"),
        max = $spinnerInput.attr("data-max"),
        isChildrenInput = $spinnerInput.attr("data-is-children") == "1",
        $spinnerWrapper = $("<span class='spinner-wrapper'></span>")
      ;
      // Create onchange handler
      if (isChildrenInput) {
        $spinnerInput.on('spinner:changed', function(){
          updateChildren($(this));
        });
      }
      // Wrap around input
      $spinnerInput.wrap($spinnerWrapper);
      // Add buttons
      var $buttonMinus = $("<span class='spiner-bt minus'>-</span>");
      $buttonMinus.on('click', function(){
        var newVal = parseInt($spinnerInput.val()) - 1;
        if (newVal >= min && newVal <= max) {
          $spinnerInput.val(newVal).trigger('spinner:changed');
        }
      });
      $buttonMinus.prependTo($spinnerWrapper);
      var $buttonPlus = $("<span class='spiner-bt plus'>+</span>");
      $buttonPlus.on('click', function(){
        var newVal = parseInt($spinnerInput.val()) + 1;
        if (newVal >= min && newVal <= max) {
          $spinnerInput.val(newVal).trigger('spinner:changed');
        }
      });
      $buttonPlus.appendTo($spinnerWrapper);
      // Mark as initialized
      $spinnerInput.addClass("initialized");
    });
  }
});
