// ALF: A Little Framework
// v3.0.xxxxxx (edge)


// Configuration
var alfCfg = {
  
  // Components main class. Set to false to disable component
  tabsClass: "tabs",
  collapsiblesClass: "collapsible",
  responsiveNavClass: "alf-nav",
  colsClass: "cols",
  
  // Default transitions
  responsiveNavTransition: "slide" // none|show|fade|slide
};

// Get the url anchor (if any)
var urlAnchor = location.hash.slice(1);
if (typeof urlAnchor == 'undefined' || !urlAnchor) urlAnchor = false;




// Cash extensions
$.fn.extend({
  
  fadeIn: function(duration = false) {
    return this.each(function() {
      var $this = $(this);
      if ($this.hasClass("alf-fade-in")) return;
      
      if (duration != false) {
        $this.css('animation-duration', duration + "s");
      }
      $this.removeClass("alf-fade-out").addClass("alf-fade-in");
    });
  },
  
  fadeOut: function(duration = false) {
    return this.each(function() {
      var $this = $(this);
      if ($this.hasClass("alf-fade-out")) return;
      if ($this.hasClass("alf-start-faded-out") && !$this.hasClass("alf-fade-in")) return;
      
      if (duration != false) {
        $this.css('animation-duration', duration + "s");
      }
      $this.removeClass("alf-fade-in").addClass("alf-fade-out");
    });
  },
  
  slideDown: function(callback = false) {
    return this.each(function() {
      var transitionTime = this.scrollHeight / 4000 + 0.3;
      this.style.transitionDuration = transitionTime + "s";
      this.style.height = `${this.scrollHeight}px`;
      var element = this;
      setTimeout(function() {
        element.style.height = "auto"; element.style.transitionDuration = "0s";
        if (callback != false) callback();
       }, transitionTime * 1000);
    });
  },
  
  slideUp: function(callback = false) {
    return this.each(function() {
      this.style.height = `${this.scrollHeight}px`;
      var transitionTime = this.scrollHeight / 4000 + 0.3; // NOTE: weirdly, if I don't at least call this.scrollHeight before the transition, it becomes automatic
      this.style.transitionDuration = transitionTime + "s";
      this.style.height = 0;
      if (callback != false) setTimeout(function() { callback(); }, transitionTime * 1000);
    });
  }
});



// On document load
$(document).ready(function(){
  
  // Global stuff
  var locationHash = window.location.hash
  
  // TODO: re-implement this
  // On AJAX complete, initialize components
  // $(document).ajaxComplete(function() {
  //   if (alfCfg['tabsClass']) tabsInitialize();
  //   if (alfCfg['collapsiblesClass']) collapsibleInitialize();
  //   if (alfCfg['responsiveNavClass']) navInitialize();
  //   if (alfCfg['colsClass']) colsInitialize();
  // });
  
  
  
  
  // Trigger/target
  $("[data-alf-trigger]").each(function(){
    var
      $trigger = $(this),
      $target = $("[data-alf-target='" + $trigger.attr("data-alf-trigger") + "']"),
      linkId = $trigger.attr('data-alf-trigger-link')
    ;
    
    // Checkboxes
    if ($trigger.is("input[type=checkbox]")) {
      // Initial state
      if ($trigger.is(':checked')) {
        $target.slideDown();
      }
      // Event handler
      $trigger.on('click', function(){
        if ($trigger.is(':checked')) {
          closeLinkedTriggers(linkId);
          $target.slideDown();
        }
        else {
          $target.slideUp();
        }
      });
    }
    
    // Other elements
    else {
      // Initial state
      if ($trigger.is("[data-alf-starts-open]")) {
        $target.slideDown();
      }
      // Event handler
      $trigger.on('click', function(){
        if ($target.css("height") == "0px") {
          closeLinkedTriggers(linkId);
          $target.slideDown();
        }
        else {
          $target.slideUp();
        }
      });
    }
  });
  
  function closeLinkedTriggers(linkId) {
    // Any (opened) linked triggers?
    if (linkId) {
      $("[data-alf-trigger-link='" + linkId + "']").each(function(){
        var $trigger = $(this);
        var $target = $("[data-alf-target='" + $trigger.attr("data-alf-trigger") + "']");
        if ($target.css("height") != "0px") {
          $target.slideUp();
        }
      });
    }
  }
  
  
  
  
  
  
  // Swap checkboxes
  $("[data-alf-swap]").each(function(){
    var $trigger = $(this);
    // Checkboxes
    if ($trigger.is("input[type=checkbox]")) {
      // Initial state
      var $targetOn = $("[data-alf-swap-on='" + $trigger.attr("data-alf-swap") + "']");
      var $targetOff = $("[data-alf-swap-off='" + $trigger.attr("data-alf-swap") + "']");
      if ($trigger.is(':checked')) {
        $targetOn.slideDown();
      }
      else {
        $targetOff.slideDown();
      }
      // Event handler
      $trigger.on('click', function(){
        if ($trigger.is(':checked')) {
          $targetOn.slideDown();
          $targetOff.slideUp();
        }
        else {
          $targetOn.slideUp();
          $targetOff.slideDown();
        }
      });
    }
    // FUTURE: for other elements, toggle on click
  });
  
  
  
  
  
  
  // Tabs
  if (alfCfg['tabsClass']) {

    // Click event: tab
    $(document).on('click', '.' + alfCfg['tabsClass'] + ">ul>li>a", function(e, isInitClick){
      var
        $tabTrigger = $(this),
        $tabWrapper = $tabTrigger.closest('.' + alfCfg['tabsClass']),
        $currentTab = $tabWrapper.find('.tabs-active').first(),
        isExternal = $tabTrigger.hasClass('external')
      ;

      if ($currentTab) {
        // Skip clicks on selected tab
        if (!isInitClick && ($currentTab.attr('href') == $tabTrigger.attr('href'))) return;

        // De-activate current
        $currentTab.removeClass('tabs-active').parent().removeClass('selected');
        if (!$currentTab.hasClass('external')) $($currentTab.attr('href')).hide();
      }

      if (!isExternal || isInitClick) e.preventDefault();
      if (isExternal) {
        if (isInitClick) {
          $tabWrapper.find('.tabs-label').first().html($tabTrigger.html());
          $tabTrigger.addClass('tabs-active').parent().addClass('selected');
        }
        return true;
      }
      
      $tabWrapper.find('.tabs-label').first().html($tabTrigger.html());
      $tabTrigger.addClass('tabs-active').parent().addClass('selected');
      $($tabTrigger.attr('href')).show();
      if ($tabWrapper.hasClass("opened")) {
        //$tabWrapper.find('.tabs-bar').slideUp(function(){ $tabWrapper.removeClass("opened"); });
        $tabWrapper.removeClass("opened").find('.tabs-bar').hide();
      }
    });
    
    // Click event: label (when collapsed)
    $(document).on('click', '.' + alfCfg['tabsClass'] + ">.tabs-label", function(e){
      var
        $tabTrigger = $(this),
        $tabWrapper = $tabTrigger.closest('.' + alfCfg['tabsClass']),
        $tabs = $tabWrapper.children("ul").first()
      ;
      e.preventDefault();
      if ($tabWrapper.hasClass("opened")) {
        //$tabWrapper.find('.tabs-bar').slideUp(function(){ $tabWrapper.removeClass("opened"); });
        $tabWrapper.removeClass("opened").find('.tabs-bar').hide();
      }
      else {
        $tabWrapper.addClass("opened").find('.tabs-bar').show();
      }
    });

    tabsInitialize();
  }
  
  
  function tabsInitialize() {
    var $scrollTargetForHash;
    
    $("." + alfCfg['tabsClass']).each(function(){
      
      // Have they already been initialized?
      if ($(this).hasClass('alf-initialized')) {
        return;
      }
      else {
        $(this).addClass('alf-initialized');
      }
      
      var
        $tabWrapper = $(this),
        $tabBar = $tabWrapper.children('ul').first(),
        $tabTriggers = $tabBar.children().children('a'), // >li>a
        $defaultTab = $tabBar.find('[data-alf-tabs-default]')
      ;
      
      // Set first tab as current (avoids problem with accessing a tab with an anchor tag)
      $tabTriggers.first().addClass("tabs-active");
      
      // Add tabs-label (used in collapsed state)
      var $label = $("<div class='tabs-label'></div>");
      $tabWrapper.prepend($label);

      // Add tabs classes
      $tabBar.addClass('tabs-bar');
      $tabTriggers.each(function(){
        var $trigger = $(this);
        // Detect external links
        if ($trigger.attr('href')[0] != "#") {
          $trigger.addClass('external');
        }
        else {
          $($trigger.attr('href')).addClass('tabs-content');
        }
      });
  
      // Select first tab
      if (locationHash && locationHash != "done") {
        var $triggerFromHash = $tabBar.find("[href='" + locationHash + "']").first();
        if ($triggerFromHash.length == 1) {
          $defaultTab = $triggerFromHash;
          $scrollTargetForHash = $triggerFromHash.closest(".tabs");
          locationHash = "done";
        }
      }
      if ($defaultTab.length == 0) {
        $defaultTab = $tabTriggers.first();
      }
      $defaultTab.trigger('click', [true]);
    });
    
    if (typeof $scrollTargetForHash != 'undefined') {
      setTimeout(function(){window.scrollTo({ top: $scrollTargetForHash.offset().top - 10 });}, 50);
    }
  }











  // Collapsibles
  if (alfCfg['collapsiblesClass']) {
    // Click on trigger
    $(document).on('click', '.' + alfCfg['collapsiblesClass'] + " .collapsible-trigger", function(e){
      var
        $collapsibleTrigger = $(this),
        $collapsible = $collapsibleTrigger.closest('.' + alfCfg['collapsiblesClass']),
        $collapsibleContent = $collapsible.find('.collapsible-content').first(),
        textOpened = $collapsible.attr('data-alf-collapsible-text-opened'),
        textClosed = $collapsible.attr('data-alf-collapsible-text-closed'),
        linkId = $collapsible.attr('data-alf-collapsible-link')
      ;
      e.preventDefault();
      if ($collapsible.hasClass('collapsible-open')) {
        $collapsible.removeClass('collapsible-open');
        $collapsibleContent.slideUp();
        if (textClosed) $collapsibleTrigger.text(textClosed);
      }
      else {
        // Any (opened) linked collapsibles?
        if (linkId) {
          $("." + alfCfg['collapsiblesClass'] + ".collapsible-open" + "[data-alf-collapsible-link='" + linkId + "']").each(function(){
            var $linked = $(this);
            var linkedTextClosed = $linked.attr('data-alf-collapsible-text-closed');
            $linked.removeClass('collapsible-open');
            $linked.find('.collapsible-content').first().slideUp();
            if (linkedTextClosed) $linked.find('.collapsible-trigger').first().text(linkedTextClosed);
          });
        }
        
        // Open
        $collapsible.addClass('collapsible-open');
        $collapsibleContent.slideDown();
        if (textOpened) $collapsibleTrigger.text(textOpened);
      }
    });
    
    collapsibleInitialize();
  }
  
  
  function collapsibleInitialize() {
    // Open collapsibles that start open
    $('.' + alfCfg['collapsiblesClass']).each(function(){

      // Have they already been initialized?
      if ($(this).hasClass('alf-initialized')) {
        return;
      }
      else {
        $(this).addClass('alf-initialized');
      }

      var
        $collapsible = $(this),
        $collapsibleTrigger = $collapsible.find('.collapsible-trigger'),
        startsOpen = $collapsible.attr('data-alf-collapsible-starts-open') == 'true',
        textOpened = $collapsible.attr('data-alf-collapsible-text-opened'),
        textClosed = $collapsible.attr('data-alf-collapsible-text-closed')
      ;
      
      // Change text (I'll assume that both texts will be set...)
      if (textOpened && startsOpen) {
        $collapsibleTrigger.text(textOpened);
      }
      if (textClosed && !startsOpen) {
        $collapsibleTrigger.text(textClosed);
      }
      
      // Starts open?
      if (urlAnchor && urlAnchor == $collapsible.attr("id")) startsOpen = true;
      if (startsOpen) {
        $collapsibleTrigger.trigger('click');
      }
    });
  }
  

  
  
  
  
  
  
  

  // Responsive navigation
  // Requires: a container (generally a <nav>) with the responsive nav class (default .alf-nav), and two children with classes .alf-trigger and .alf-menu
  if (alfCfg['responsiveNavClass']) {
    navInitialize();
  }
  
  
  function navInitialize() {
    $('.' + alfCfg['responsiveNavClass']).each(function(){

      // Have they already been initialized?
      if ($(this).hasClass('alf-initialized')) {
        return;
      }
      else {
        $(this).addClass('alf-initialized');
      }

      var
        $container = $(this),
        $trigger = $container.find(".alf-trigger"),
        $menu = $container.find(".alf-menu"),
        $menuLinks = $menu.find("a")
      ;
      
      var transition = $container.attr('data-alf-nav-transition');
      if (!transition) {
        transition = alfCfg['responsiveNavTransition'];
      }
      $container.addClass("alf-transition-" + transition);
      
      // Add bars to trigger
      $trigger
        .wrapInner("<span class='original-content'></span>")
        .prepend("<span class='bars'><span class='bar top'></span><span class='bar middle'></span><span class='bar bottom'></span></span>");
      
      // Trigger to open nav
      $trigger.on('click', function(){
        
        if ($container.hasClass('alf-nav-open')) {
          $container.removeClass('alf-nav-open');
          switch (transition) {
            case 'show': $menu.hide(); break;
            case 'fade': $menu.fadeOut(); break;
            case 'slide': $menu.slideUp(); break;
          }
        }
        else {
          if ($container.attr("data-alf-scroll-to-zero-on-open")) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
          $container.addClass('alf-nav-open');
          switch (transition) {
            case 'show': $menu.show(); break;
            case 'fade': $menu.fadeIn(); break;
            case 'slide': $menu.slideDown(); break;
          }
        }
      });
      
      // Add alf- classes
      $menuLinks.each(function(){
        var
          $link = $(this),
          $li = $link.parent(),
          $submenu = $li.children("a").next()
        ;
        
        if (typeof $submenu.html() != 'undefined') { // has a submenu
          $li.addClass("alf-with-submenu");
          $submenu.addClass("alf-submenu");
        }
      });

      // Use click?
      if (typeof $container.attr("data-alf-use-click") != 'undefined') {
        $menuLinks.each(function(){
          var
            $link = $(this),
            $li = $link.parent(),
            $ul = $li.parent(),
            $submenu = $li.children("a").next(),
            inSubmenu = $link.closest(".alf-submenu").length > 0,
            useClickInSubmenus = (typeof $container.attr("data-alf-use-click-in-submenus") != 'undefined')
          ;
          
          if (
            (typeof $submenu.html() != 'undefined') &&
            (!inSubmenu || useClickInSubmenus)
          ) {
            // Add event
            $link.on('click', function(e){
              if ($li.hasClass("alf-active")) {
                $li.removeClass("alf-active");
              }
              else {
                $ul.find(".alf-active").removeClass("alf-active");
                $li.addClass("alf-active");
              }
              e.preventDefault();
              return false;
            });
          }
        });
      };
    });
  }
  
  
  
  
  
  
  // Responsive (linearized) tables
  $('.linearized-phone, .linearized-tablet').each(function(){
    var $table = $(this);
    var headers = [];
    
    $table.children("thead").find("th").each(function() {
      var txt = $(this).html();
      if (txt != "") txt = "<span class='heading'>" + txt + "</span> ";
      headers.push(txt);
		});
    
    $table.children("tbody").find("tr").each(function(){ // loop TRs
      var i = 0;
      $(this).children().each(function(){ // loop TH/TDs
        $(this).prepend(headers[i]);
        i++;
      });
    });
  });
  
});
