$().ready(function(){
  $(".c-rate-control").each(function(){
    var $control = $(this);
    var $hidden = $control.find("input[type='hidden']");
    var $items = $control.find(".item");
    var $nsnc = $control.find(".nsnc");
    
    $items.each(function(){
      var $item = $(this);
      $item.on('click', function(){
        if ($item.hasClass("disabled")) return;
        $items.removeClass("selected");
        $item.addClass("selected");
        $hidden.val($item.html());
      });
    });
    
    if ($nsnc) {
      $nsnc.find("[type='checkbox']").on('change', function(){
        $items.removeClass("selected");
        if (this.checked) {
          $hidden.val("0");
          $items.addClass("disabled");
        }
        else {
          $hidden.val("");
          $items.removeClass("disabled");
        }
      });
    }
  });
});
