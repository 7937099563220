import SmoothScroll from "smooth-scroll";

$().ready(function(){
  var $scrollTop = $('#scroll-top');

  $(window).on('scroll', function() {
    if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
      $scrollTop.fadeIn();
    }
    else {
      if ($scrollTop.css("opacity") == "1") $scrollTop.fadeOut();
    }
  });
  
  new SmoothScroll('#scroll-top', {
    easing: 'easeOutCubic',
    durationMax: 1000,
  });
});
