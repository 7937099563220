import Swiper, { Pagination, Navigation, Autoplay } from 'swiper';

// configure Swiper to use modules
Swiper.use([Pagination, Navigation, Autoplay]);

$().ready(function(){
  
  $(".c-swiper").each(function(){
    var $swiper = $(this);
    var useNavigation = $swiper.hasClass("navigation");
    var slidesPerView = 1;
    var spaceBetween = 0;
    var loop = $swiper.hasClass("loop");
    var autoplay = $swiper.hasClass("autoplay");
    var hint = 0;
    var hasHint = false;
    
    // Slides per view
    if ($swiper.hasClass("show-2")) slidesPerView = 2;
    else if ($swiper.hasClass("show-3")) slidesPerView = 3;
    else if ($swiper.hasClass("show-4")) slidesPerView = 4;
    else if ($swiper.hasClass("show-5")) slidesPerView = 5;
    else if ($swiper.hasClass("show-6")) slidesPerView = 6;
    
    // Hint (of prev/next slides)
    if ($swiper.hasClass("hint-s")) { slidesPerView += 0.1; hasHint = true; }
    else if ($swiper.hasClass("hint-m")) { slidesPerView += 0.3; hasHint = true; }
    else if ($swiper.hasClass("hint-l")) { slidesPerView += 0.5; hasHint = true; }
    
    // Space between
    if ($swiper.hasClass("separation-s")) spaceBetween = 6;
    if ($swiper.hasClass("separation-l")) spaceBetween = 60;
    
    // Prepare config hash
    var swiperCfg = {
      direction: 'horizontal',
      loop: loop,
      watchOverflow: true,
      centeredSlides: hasHint,
    };
    if (slidesPerView > 1) {
      swiperCfg['slidesPerView'] = slidesPerView;
      if (!hasHint) swiperCfg['slidesPerGroup'] = slidesPerView;
    }
    if (spaceBetween > 0) swiperCfg['spaceBetween'] = spaceBetween;
    if (useNavigation) {
      swiperCfg['navigation'] = {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
      };
    }
    else {
      swiperCfg['pagination'] = {
        el: '.swiper-pagination-h',
        clickable: true,
      };
    }
    if (autoplay) {
      swiperCfg['autoplay'] =  {
        delay: 5000,
      };
    }
    
    // Create swiper
    new Swiper(this, swiperCfg);
  });
  
});
