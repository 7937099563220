// main.js

import JSShare from "js-share";

$().ready(function(){

  // js-share.js
  var $items = $(".c-social-share-buttons>li");
  $items.on('click', function(){
    return JSShare.go(this);
  });

});
